import React from "react";
import "../Course/style.css";
import Box from "../Dashboard/Box";
const Tutors = () => {
  return (
    <div>
      <section class="c-pageBanner c-pageBanner--courses sectionContainer">
        <div class="c-pageBanner__backgroundOverlay"></div>
        <div class="c-pageBanner__container  responsivePadding">
          <div class="c-pageBanner__content">
            <h1 class="c-pageBanner__heading">Tutors</h1>
          </div>
          <img
            src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/hero/hero1.jpg"
            alt=""
            class="c-pageBanner__img"
          />
        </div>
      </section>
      <div class="spacing-48px"></div>
      <section class="sectionContainer bgPureWhite">
        <div class="c-iconblks c-iconblks--blue responsivePadding">
          <h2 class="c-iconblks__title c-iconblks__title--teal">
            Learn with us ...
          </h2>
          <div class="c-iconblks__container c-iconblks__container--triple">
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/onlineTrainingVideos.svg" />
              <p class="c-iconblks__contentTitle">Online training videos</p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/expertTrainingProviders.svg" />
              <p class="c-iconblks__contentTitle">Expert training providers</p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/subjectSpecificFocus.svg" />
              <p class="c-iconblks__contentTitle">Subject specific focus</p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/learnAtYourOwnPace.svg" />
              <p class="c-iconblks__contentTitle">Learn at your own pace</p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/professionalCertification.svg" />
              <p class="c-iconblks__contentTitle">Professional certification</p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/sharingGoodPractice.svg" />
              <p class="c-iconblks__contentTitle">Sharing good practice</p>
            </div>
          </div>
        </div>
      </section>
      <div class="spacing-48px"></div>

      <div class="sectionContainer">
        <section class="c-course responsivePadding">
          <div class="c-course__intro">
            <div class="c-course__content">
              <h2 class="c-course__title">Microsoft Teams</h2>
              <p class="c-course__text">
                Microsoft Teams for schools makes it easy for teachers to set up
                virtual classrooms, keep assignments organised, and collaborate
                on Word, Excel, and PowerPoint files in real time..
              </p>
              <div class="c-course__actions">
                {/* <button
                  type="button"
                  class="c-course__button c-course__button--close toggle-button"
                >
                  <img
                    src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/plus-solid.svg"
                    alt="close icon"
                    class="c-course__buttonIcon"
                  />
                </button> */}
                <a
                  type="button"
                  class="c-button ctaOrange"
                  style={{
                    textDecoration: "auto",
                  }}
                  href="https://demo.nexonetics.com/register-2/"
                >
                  Register as a tutor
                </a>
              </div>
            </div>

            <img
              src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/courses/teams.png"
              alt=""
              class="c-course__img"
            />
          </div>
          {/* <div class="c-course__details">
                <div class="c-course__detailsLine"></div>
                <h5 class="c-course__detailsTitle">Course Details</h5>
                <p class="c-course__detailsText">
                This course includes a range of video tutorials on the different
                tools and functionalities available in each of the following
                sections.
                </p>
                <div class="c-course__listContainer">
                <ul class="c-course__list">
                    <li class="c-course__listItem">Activity</li>
                    <li class="c-course__listItem">Class Notebook</li>
                    <li class="c-course__listItem">Grades</li>
                    <li class="c-course__listItem">Assignments</li>
                </ul>
                <ul class="c-course__list">
                    <li class="c-course__listItem">Chat</li>
                    <li class="c-course__listItem">Classwork</li>
                    <li class="c-course__listItem">Reflect</li>
                    <li class="c-course__listItem">Calendar</li>
                </ul>
                <ul class="c-course__list">
                    <li class="c-course__listItem">Teams</li>
                    <li class="c-course__listItem">Channels</li>
                    <li class="c-course__listItem">Insights</li>
                    <li class="c-course__listItem">Calls</li>
                </ul>
                </div>
            </div> */}
        </section>
      </div>
      <div class="spacing-48px"></div>
      <section class="sectionContainer ">
        <div class="c-iconblks c-iconblks--teal responsivePadding">
          <h2 class="c-iconblks__title c-iconblks__title--blue">
            ... and address the challenges
          </h2>
          <div class="c-iconblks__container c-iconblks__container--quad">
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/computerTime.svg" />
              <p class="c-iconblks__fixedText">
                Offer excellent time-saving and collaborative training, despite
                stretched budgets
              </p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/computerSeminar.svg" />
              <p class="c-iconblks__fixedText">
                Improve digital learning and enable your students to gain the
                necessary digital skills
              </p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/atom.svg" />
              <p class="c-iconblks__fixedText">
                Enable diversity, accessibility and inclusion within all areas
                and aspects of school life
              </p>
            </div>
            <div class="c-iconblks__iconblk">
              <img src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/jigsaw.svg" />
              <p class="c-iconblks__fixedText">
                Adapt to all the curriculum updates and the pressure for grade
                improvements
              </p>
            </div>
          </div>
        </div>
      </section>
      <div class="spacing-48px"></div>
      <div className="bgContainer linearBG">
        <Box></Box>

        <div class="spacing-48px"></div>
        <div class="sectionContainer buttons-div">
          <a
            class="c-button ctaOrange"
            style={{
              background: "rgb(66 66 66)",
              border: "none",
              color: "#fff",
              width: "220px",
              borderRadius: "10px",
              marginRight: "35px",
              textDecoration: "auto",
            }}
            href="https://demo.nexonetics.com/register-2/"
          >
            Register{" "}
          </a>
          <a
            class="c-button ctaOrange"
            style={{
              background: "rgb(66 66 66)",
              border: "none",
              color: "#fff",
              width: "220px",
              borderRadius: "10px",
              textDecoration: "auto",
            }}
            href="
           https://demo.nexonetics.com/my-account/
"
          >
            Login
          </a>
        </div>
      </div>
    </div>
  );
};

export default Tutors;
