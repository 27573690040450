import React from "react";
import "./style.css"; // Make sure to create this CSS file

const Teachers = () => {
  return (
    <div className="teachers-container lgDark">
      <div className="teachers-card">
        <div className="teachers-content">
          <p>
            At Learnogic, we value working with highly creative, dedicated and
            motivated people who look to develop student learning. We appreciate
            the significance of being a senior examiner – be it an assistant
            principal or a team leader – and the contribution your many years of
            teaching and examining experience can make in enhancing the writing
            skills of students.
          </p>

          <p>
            Whether you are aiming to diversify your work portfolio, leaving
            education, or are looking for a fresh challenge, we could be your
            calling. If you wish to be a key part of our innovative journey in
            enriching every student's learning experience or would simply like
            to find out more about the roles within our subjects, get in touch.
          </p>
        </div>

        <div className="button-container">
          <button
            onClick={() => (window.location.href = "/contact")}
            className="c-button"
            style={{ display: "block" }}
          >
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default Teachers;
