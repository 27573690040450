import React from "react";
import "./style.css";
import logo from "../../Assets/Images/Logo-Bird-2.png";
import { useNavigate } from "react-router-dom";
import { Facebook } from "lucide-react";

const Footer = () => {
  const history = useNavigate();

  return (
    <div>
      <footer class="sectionContainer bgBlueDark" style={{ display: "block" }}>
        <div
          class="c-footer responsivePadding"
          style={{ padding: "80px 180px 50px 180px" }}
        >
          <div class="c-footer__topGroup">
            <div
              class="c-footer__group c-footer__group--left"
              style={{ width: 200, display: "block", textAlign: "justify" }}
            >
              <h3 style={{ margin: 4 }}>Cultivating Excellence</h3>
              <div className="cultivate">
                {" "}
                We empower you to master the art of writing and take it to the
                next level.
              </div>
            </div>
            <div class="c-footer__group c-footer__group--right">
              <div
                style={{
                  display: "flex",

                  justifyContent: "space-between",
                  padding: 13,
                  // background: "#ffd787d1",
                  borderRadius: 12,
                }}
              >
                {/* <a href="">
            <img src={logo} alt="" class="c-nav__logo" />
          </a> */}
                <div>
                  <a href="">
                    <img src={logo} alt="" class="c-nav__logo" />
                  </a>
                </div>
                <div style={{ marginLeft: 5, cursor: "pointer" }}>
                  <div
                    style={{
                      fontFamily: "montserrat",
                      fontSize: 35,
                      fontWeight: 700,
                      letterSpacing: 2,
                    }}
                  >
                    Learnogic
                  </div>
                  <div
                    style={{
                      fontFamily: "montserrat",
                      fontSize: 15,
                      fontWeight: 600,
                      letterSpacing: 2,
                      color: "#fff  ",
                    }}
                  >
                    excel with flying colours
                  </div>
                </div>
              </div>{" "}
              <ul class="c-footer__links">
                <a
                  onClick={() => {
                    history("/subjects");
                  }}
                  class="c-footer__link"
                >
                  Subjects
                </a>
                <a
                  onClick={() => {
                    history("/teachers-and-examiners");
                  }}
                  class="c-footer__link"
                >
                  Teachers and Examiners
                </a>
                <a href="" class="c-footer__link">
                  FAQs
                </a>
                <a
                  onClick={() => {
                    history("/contact");
                  }}
                  class="c-footer__link"
                >
                  Contact us
                </a>
              </ul>
              <ul class="c-socialIcons">
                <a href="">
                  <i class="fa-brands fa-facebook"></i>
                </a>
                <a href="">
                  <i class="fa-brands fa-twitter"></i>
                </a>
                <a href="">
                  <i class="fa-brands fa-pinterest"></i>
                </a>
                <a href="">
                  <i class="fa-brands fa-youtube"></i>
                </a>
              </ul>
            </div>
          </div>
          <div class="c-footer__tnc">
            <a href="">Terms &amp; Conditions</a>|<a href="">Privacy Policy</a>©
            Copyright Learnogic
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
