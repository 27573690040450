import React, { useState } from "react";
import "./style.css";
import Box from "../Dashboard/Box";

const Course = () => {
  const [show, setShow] = useState(false);

  const handleChange = () => {
    setShow(!show);
  };

  return (
    <div>
      <section className="c-pageBanner c-pageBanner--courses sectionContainer">
        <div className="c-pageBanner__backgroundOverlay"></div>
        <div className="c-pageBanner__container responsivePadding">
          <div className="c-pageBanner__content">
            <h1 className="c-pageBanner__heading">Subjects</h1>
          </div>
          <img
            src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/hero/hero1.jpg"
            alt=""
            className="c-pageBanner__img"
          />
        </div>
      </section>
      <div
        style={{
          paddingTop: 0,
          width: "auto",
          paddingLeft: 200,
        }}
        className="c-pageBanner__container responsivePadding"
      >
        {" "}
        <p className="c-pageBanner__intro">
          Learnogic was designed to empower every student to master the art of
          writing and to take it to the next level. Our focus is on developing a
          bank of board-specific questions, by topic, for a range of essay-based
          subjects that require careful integration of all the assessment
          objectives when structuring answers. The content has been contributed
          and verified by a team of senior examiners.
        </p>
      </div>
      <div className="spacing-48px"></div>

      <div className="spacing-48px"></div>

      <div className="sectionContainer">
        <section className="c-course responsivePadding">
          <div className="c-course__intro">
            <div className="c-course__content">
              <h2 className="c-course__title">Edexcel Economics</h2>
              <div className="c-course__text">
                {/* Removed the previous text content */}
              </div>
              <div className="c-course__actions">
                <button
                  type="button"
                  className="c-course__button c-course__button--close toggle-button"
                  onClick={handleChange}
                >
                  <img
                    src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/plus-solid.svg"
                    alt="toggle icon"
                    className="c-course__buttonIcon"
                  />
                </button>
                <a
                  className="c-button ctaOrange"
                  style={{
                    textDecoration: "none",
                  }}
                  href="https://demo.nexonetics.com/register/"
                >
                  Register as a Student
                </a>
              </div>
            </div>
          </div>

          {show && (
            <div className="c-course__details expanded">
              <div className="c-course__detailsLine"></div>
              <h5 className="c-course__detailsTitle">Course Details</h5>
              {/* <p className="c-course__detailsText">
                This course includes the following key learning elements:
              </p> */}
              <ul className="c-course__list">
                <li>
                  Essays provide an in-depth understanding of how to structure
                  answers and integrate all the assessment objectives to attain
                  top grades.
                </li>
                <li>
                  Past exam questions allow self-assessment of written work
                  against our exemplars to ensure all the details in the level
                  descriptors are covered.
                </li>
                <li>
                  Multiple choice questions, through regular retrieval practice,
                  enhance learning by checking for understanding and identifying
                  misconceptions.
                </li>
                <li>
                  Diagrams, if accurately drawn and integrated, enable the
                  development of arguments and elevate them to a higher level;
                  learn to draw step-by-step.
                </li>
              </ul>
            </div>
          )}
        </section>
      </div>
      <div className="spacing-48px"></div>
      <section className="sectionContainer">
        <div className="c-iconblks c-iconblks--teal responsivePadding">
          <h2 className="c-iconblks__title c-iconblks__title--blue">
            Coming soon
          </h2>
          <div className="c-iconblks__container c-iconblks__container--quad">
            {[
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/computerTime.svg",
                text: "AQA Business",
              },
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/computerSeminar.svg",
                text: "AQA Economics",
              },
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/atom.svg",
                text: "AQA Psychology",
              },
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/jigsaw.svg",
                text: "AQA Sociology",
              },

              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/atom.svg",
                text: "Edexcel Business",
              },
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/jigsaw.svg",
                text: "Edexcel Politics",
              },
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/computerSeminar.svg",
                text: "OCR Religious Studies",
              },
            ].map((item, index) => (
              <div key={index} className="c-iconblks__iconblk">
                <img src={item.src} alt="" />
                <p className="c-iconblks__fixedText">{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="sectionContainer bgPureWhite">
        <div className="c-iconblks c-iconblks--blue responsivePadding">
          <h2 className="c-iconblks__title c-iconblks__title--teal">
            Learn with us
          </h2>
          <div className="c-iconblks__container c-iconblks__container--triple">
            {[
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/professionalCertification.svg",
                title: "Top level model answers",
              },
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/expertTrainingProviders.svg",
                title: "Senior examiners",
              },
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/subjectSpecificFocus.svg",
                title: "Subject specific focus",
              },
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/learnAtYourOwnPace.svg",
                title: "Learn at your own pace",
              },
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/computerTime.svg",
                title: "Time-saving learning",
              },
              {
                src: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/svg/sharingGoodPractice.svg",
                title: "Sharing good practice",
              },
            ].map((item, index) => (
              <div key={index} className="c-iconblks__iconblk">
                <img src={item.src} alt={item.title} />
                <p className="c-iconblks__contentTitle">{item.title}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <div className="spacing-48px"></div>
      <div className="bgContainer linearBG">
        <Box />
        <div className="spacing-48px"></div>
        <div className="sectionContainer buttons-div">
          <a
            className="c-button ctaOrange"
            style={{
              background: "rgb(66 66 66)",
              border: "none",
              color: "#fff",
              width: "220px",
              borderRadius: "10px",
              marginRight: "35px",
              textDecoration: "none",
            }}
            href="https://demo.nexonetics.com/register/"
          >
            Register
          </a>
          <a
            className="c-button ctaOrange"
            style={{
              background: "rgb(66 66 66)",
              border: "none",
              color: "#fff",
              width: "220px",
              borderRadius: "10px",
              textDecoration: "none",
            }}
            href="https://demo.nexonetics.com/my-account/"
          >
            Login
          </a>
        </div>
      </div>
    </div>
  );
};

export default Course;
