import React, { useEffect, useState } from "react";
import "./style.css";
import HeroSection from "./HeroSection";
import Box from "./Box";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const history = useNavigate();
  const heroData = [
    {
      text: " productivity gains",
      sectionClass: "c-hero",
      img: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/hero/hero1.jpg",
    },
    {
      text: " raise assessment levels",
      sectionClass: "c-hero1",
      img: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/hero/hero3.jpg",
    },
    {
      text: " attain highest outcomes",
      sectionClass: "c-hero2",
      img: "https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/hero/hero2.jpg",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % heroData.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="dashboard-div">
      <HeroSection
        text={heroData[currentIndex].text}
        sectionClass={heroData[currentIndex].sectionClass}
        img={heroData[currentIndex].img}
      />

      <section className="sectionContainer">
        <div className="c-intro">
          <div className="c-intro__contentGroup c-intro__contentGroup--double">
            <div className="c-intro__content">
              <div className="c-intro__title">Who are we?</div>
              <p className="c-intro__text">
                Learnogic was designed to provide a platform of sharing best
                practise. Be it support for homework, assignments and/or
                assessments, we offer an easy one-click access to a
                comprehensive selection of exam questions.
              </p>
            </div>
            <div className="c-intro__content">
              <div className="c-intro__title">Why Learnogic?</div>
              <p className="c-intro__text">
                Our comprehensive range of model answers improves the quality of
                your written work and ensures that you develop a much stronger
                understanding of the exam technique essential for the study of
                your subject(s).
              </p>
            </div>
          </div>
          <div className="c-intro__contentGroup c-intro__contentGroup--left">
            <img
              src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/img/intro-image1.png"
              alt=""
              className="c-intro__img"
            />
            <div className="c-intro__content">
              <div className="c-intro__title">Make productivity gains</div>
              <p className="c-intro__text">
                Maximise efficacy by making the optimum use of time. Quickly
                search for key terms and questions across exam papers and our
                resource bank, with one-click access to answers. Our centralised
                approach extensively reduces inefficiencies in learning.
              </p>
            </div>
          </div>
          <div className="c-intro__contentGroup c-intro__contentGroup--right">
            <img
              src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/img/intro-image2.png"
              alt=""
              className="c-intro__img"
            />
            <div className="c-intro__content">
              <div className="c-intro__title">Raise assessment levels</div>
              <div className="c-intro__text">
                To achieve the highest level, it is essential to address the
                demands of each exam question by incorporating all the necessary
                assessment objectives (AOs) into your written response. Our
                model answers indicate how to approach different types of
                questions.
              </div>
            </div>
          </div>
          <div className="c-intro__contentGroup c-intro__contentGroup--left">
            <img
              src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/img/intro-image3.png"
              alt=""
              className="c-intro__img"
            />
            <div className="c-intro__content">
              <div className="c-intro__title">Attain highest outcomes</div>
              <div className="c-intro__text">
                While learning content is vital, writing to the necessary
                standard is imperative. By following the content and the
                structure of our range of model answers, there will be a
                significant increase in the quality of your written work, and
                hence, better results.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sectionContainer bgPureWhite">
        <div className="c-coursesTb responsivePadding">
          <div className="c-coursesTb__header">
            <h2 className="c-coursesTb__headerHeading">
              Learning with our experts
            </h2>
            <p className="c-coursesTb__headerIntro">
              Our training providers are <strong>experienced teachers</strong>{" "}
              who themselves have successfully integrated digital technology in
              all their subject areas across different key stages and year
              groups.
            </p>
          </div>
          <div className="c-coursesTb__thumbnails">
            <div className="c-coursesTb__thumbnail">
              <img
                src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/courses/oneNote.png"
                alt=""
                className="c-coursesTb__thumbnailImg"
              />
              <div className="c-tag c-tag--bestSeller">Best seller</div>
              <div className="c-coursesTb__thumbnailTitle">
                Resource bank
                <p className="thumbnail-description">
                  Access 1000s of questions, mark schemes and model answers: all
                  with one click
                </p>
              </div>
              <div className="c-coursesTb__thumbnailDuration">10 min</div>
            </div>

            <div className="c-coursesTb__thumbnail">
              <img
                src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/courses/oneNote.png"
                alt=""
                className="c-coursesTb__thumbnailImg"
              />
              <div className="c-tag c-tag--bestSeller">Best seller</div>
              <div className="c-coursesTb__thumbnailTitle">
                Board specific
                <p className="thumbnail-description">
                  Questions and answers mirror requirements of awarding
                  organisations
                </p>
              </div>
              <div className="c-coursesTb__thumbnailDuration">10 min</div>
            </div>

            <div className="c-coursesTb__thumbnail">
              <img
                src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/courses/oneNote.png"
                alt=""
                className="c-coursesTb__thumbnailImg"
              />
              <div className="c-tag c-tag--new">New</div>
              <div className="c-coursesTb__thumbnailTitle">
                Senior examiners
                <p className="thumbnail-description">
                  All your answers are verified by a strong dedicated team of
                  current subject specialists
                </p>
              </div>
              <div className="c-coursesTb__thumbnailDuration">10 min</div>
            </div>

            <div className="c-coursesTb__thumbnail">
              <img
                src="https://dilepex-lms.kau.agency/wp-content/themes/wp-dilepex-website/assets/courses/classNotebook.png"
                alt=""
                className="c-coursesTb__thumbnailImg"
              />
              <div className="c-tag c-tag--bestSeller">Best Seller</div>
              <div className="c-coursesTb__thumbnailTitle">
                Refined search
                <p className="thumbnail-description">
                  Find questions on topics and content; something you have
                  always wanted
                </p>
              </div>
              <div className="c-coursesTb__thumbnailDuration">10 min</div>
            </div>
          </div>
        </div>
      </section>
      <div className="spacing-48px"></div>
      <div className="bgContainer linearBG">
        <Box />
        <section className="sectionContainer">
          <div className="c-cta">
            <div className="c-cta__title">Get in touch</div>
            <p className="c-cta__intro">
              For any queries regarding membership, school subscription or
              purchasing resources, send us a message.
            </p>
            <button
              className="c-button ctaOrange"
              style={{
                background: "rgb(66 66 66)",
                border: "none",
                color: "#fff",
              }}
              onClick={() => {
                history("/contact");
              }}
            >
              Contact us
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Dashboard;
